var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-factory-description" },
    [
      _c(
        "Collapse",
        {
          on: { change: _vm.handleChange },
          model: {
            value: _vm.activeNames,
            callback: function($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames"
          }
        },
        [
          _vm.type == 2
            ? _c("CollapseItem", { attrs: { title: "扫码质检", name: "1" } }, [
                _c("div", { staticClass: "content-text" }, [
                  _vm._v("1.点击上方的“扫码”按钮，对准设备上的二维码；")
                ]),
                _c("div", { staticClass: "content-text" }, [
                  _vm._v("2.扫描成功后，进入质检流程；")
                ]),
                _c("div", { staticClass: "content-text" }, [
                  _vm._v("3.质检通过后，确认并勾选信号和气泵的两个检查项；")
                ]),
                _c("div", { staticClass: "content-text" }, [
                  _vm._v("4.点击“质检通过”，即代表该设备已通过出厂质检。")
                ])
              ])
            : _vm._e(),
          _c("CollapseItem", { attrs: { title: "返厂定损", name: _vm.type } }, [
            _c("div", [_vm._v("1.点击“返厂定损”按钮，进入故障设备列表；")]),
            _c("div", [_vm._v("2.通过输入设备编号或扫码进入返厂详情；")]),
            _c("div", [
              _vm._v(
                "3.对设备进行定损后，在详情内点击“定损开始质检”，填写质检单后并“提交”，待览宋确认"
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }