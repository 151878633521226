require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/collapse-item/style", "vant/es/collapse-item", "vant/es/collapse/style", "vant/es/collapse"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/collapse-item/style"), require("vant/es/collapse-item"), require("vant/es/collapse/style"), require("vant/es/collapse"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.collapseItem, global.style, global.collapse);
    global.description = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _collapseItem, _style2, _collapse) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _collapseItem = _interopRequireDefault(_collapseItem);
  _collapse = _interopRequireDefault(_collapse);
  var _default = {
    name: "FactoryDescription",
    data: function data() {
      var vm = this;
      return {
        activeNames: ['1'],
        type: vm.$route.query.type
      };
    },
    components: {
      Collapse: _collapse.default,
      CollapseItem: _collapseItem.default
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);

      if (!name) {
        vm.$router.replace({
          path: "/factory/login"
        });
        return;
      }
    },
    methods: {
      handleChange: function handleChange() {}
    }
  };
  _exports.default = _default;
});